import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function Devious() {
  return (
    <Layout>
      <Helmet title="Devious | Michael Weslander & Andy Strong" />

      <h1>Devious</h1>
      <h2>Michael Weslander & Andy Strong</h2>
      <h4>Key: Em</h4>
      <h4>Tempo: 64.29</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>Am</Ch>She walked up the steps and the <Ch>Em</Ch>door was still
            shut
          </p>
          <p>
            <Ch>B</Ch>He grabbed the knob with his <Ch>Em</Ch>hand
          </p>
          <p>
            <Ch>Am</Ch>He bottled it up as she <Ch>Em</Ch>walked in the room
          </p>
          <p>
            <Ch>B</Ch>Fearing he might make a <Ch>Em</Ch>fool
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Am</Ch>He didn't know that<Ch>Em</Ch>
          </p>
          <p>
            <Ch>B</Ch>Beauty went <Ch>C</Ch>rougue<Ch>D</Ch>
          </p>
        </PreChorus>
        <Chorus>
          <p>
            <b>Em Em/B Em/C Em/B</b>
          </p>
          <p>She keeps on falling ...and I can't keep up</p>
          <p>I keep trying to tell her ... I can't make it up</p>
          <p>I'm so distracted</p>
          <p>I'm so distracted</p>
        </Chorus>
        <Verse>
          <p>
            <Ch>Am</Ch>Time passes by as he's <Ch>Em</Ch>tied to the fence
          </p>
          <p>
            <Ch>B</Ch>The threshold is non-exist<Ch>Em</Ch>ent
          </p>
          <p>
            <Ch>Am</Ch>She's digging in deep as she <Ch>Em</Ch>shows him her
            flaws
          </p>
          <p>
            <Ch>B</Ch>God must be laughing at the <Ch>Em</Ch>irony in it all
          </p>
        </Verse>
        <PreChorus>
          <p>
            <Ch>Am</Ch>She believes that it's love<Ch>Em</Ch>
          </p>
          <p>
            <Ch>B</Ch>What <Ch>C</Ch>have I <Ch>D</Ch>done
          </p>
        </PreChorus>
        <Chorus>
          <p><b>Em Em/B Em/C Em/B</b></p>
          <p>She keeps on falling ... and I can't keep up</p>
          <p>I keep trying to tell her ... I'm a little messed up</p>
          <p>I'm so distracted (I'm devious)</p>
          <p>I'm so distracted (I'm devious)</p>
        </Chorus>
        <Bridge>
          <p>Em B C D</p>
        </Bridge>
        <Chorus>
          <p>
            <b>Em Em/B Em/C Em/B</b>
          </p>
          <p>She keeps on falling ... and I can't get up</p>
          <p>I keep trying to tell her ... I can't make it up</p>
          <p>(Repeat)</p>
        </Chorus>
        <Chorus>
          <p>
            <b>Em Em/B Em/C Em/B</b>
          </p>
          <p>She keeps on falling ... and I can't get up (I'm devious)</p>
          <p>I keep trying to tell her ... this isn't enough (I'm so distracted)</p>
        </Chorus>
        <Verse>
          <p>Oh, I'm so distracted</p>
        </Verse>
      </Columns>
    </Layout>
  );
}
